import { Button, Typography } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import React from 'react';
import styles from './styles.module.less';

export const Authentication: React.FC = () => {
  const { signIn } = useAuth();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography.Title>Login</Typography.Title>
        <Typography.Text>Welcome to Fastdev</Typography.Text>
      </div>

      <Button block type={'primary'} size={'large'} className={styles.button} onClick={signIn}>
        Login with Google
      </Button>
    </div>
  );
};
