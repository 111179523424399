import { Button, Select, Typography } from 'antd';
import React from 'react';
import styles from './styles.module.less';

export const GenerateInvoice: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Typography.Title className={styles.title}>Generate invoice</Typography.Title>
          <Typography.Text className={styles.subtitle}>
            Here you can generate invoice for yourself by month.
          </Typography.Text>
        </div>

        <Select size={'large'} placeholder={'Select period'} />

        <Button type={'primary'} size={'large'} block>
          Download PDF
        </Button>
      </div>
    </div>
  );
};
