import { GenerateInvoice } from './GenerateInvoice/GenerateInvoice';
import { Tabs } from 'antd';
import React, { useState } from 'react';
import styles from './styles.module.less';

export const Home: React.FC = () => {
  const [activeTab, setActiveTab] = useState('generate');

  return (
    <div className={styles.container}>
      <Tabs
        defaultActiveKey={'1'}
        onChange={setActiveTab}
        activeKey={activeTab}
        className={styles.tabs}
        items={[
          {
            label: `Generate`,
            key: 'generate',
            children: <GenerateInvoice />,
          },
          {
            label: `List`,
            key: 'list',
            children: null,
            disabled: true,
          },
          {
            label: `Settings`,
            key: 'settings',
            children: null,
            disabled: true,
          },
        ]}
      />
    </div>
  );
};
