import { Authentication } from '../Authentication/Authentication';
import { BaseLayout } from './BaseLayout/BaseLayout';
import { Home } from '../Home/Home';
import { Navigate, Route, Routes } from 'react-router-dom';
import { WithAuth } from './helpers/WithAuth';
import { useAuth } from '../../hooks/useAuth';
import React from 'react';

export const App: React.FC = () => {
  const { isAuthenticated: isAuthorized } = useAuth();

  return (
    <BaseLayout>
      <Routes>
        <Route element={<WithAuth />}>
          <Route path={'/'} element={<Home />} />
          <Route path={'*'} element={<Navigate to={'/'} />} />
        </Route>

        {!isAuthorized && (
          <>
            <Route path={'/sign-in'} element={<Authentication />} />

            <Route path={'*'} element={<Navigate to={'/sign-in'} />} />
          </>
        )}
      </Routes>
    </BaseLayout>
  );
};
