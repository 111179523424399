import { Layout } from 'antd';
import { LayoutHeader } from './Header';
import React from 'react';
import styles from './styles.module.less';

interface ILayoutProps {
  children: React.ReactNode;
}

export const BaseLayout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <Layout className={styles.container}>
      <LayoutHeader />

      <Layout.Content className={styles.content}>{children}</Layout.Content>
    </Layout>
  );
};
