import React, { createContext, useContext, useState } from 'react';

interface IUseAuthResult {
  isAuthenticated: boolean;
  signIn: () => void;
  logout: () => void;
}

const authContext = createContext<IUseAuthResult>({
  isAuthenticated: false,
  signIn: () => {},
  logout: () => {},
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const auth = useAuthProvider();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuthProvider = (): IUseAuthResult => {
  const [isAuthenticated, setAuthenticated] = useState(false);

  const signIn = () => setAuthenticated(true);
  const logout = () => setAuthenticated(false);

  return { isAuthenticated, signIn, logout };
};

export const useAuth = () => {
  return useContext(authContext);
};
