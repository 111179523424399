import { Avatar, Button, Layout, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useAuth } from '../../../hooks/useAuth';
import React from 'react';
import logoIcon from '/src/icons/logo.svg';
import logoutIcon from '/src/icons/logout.svg';
import styles from './styles.module.less';

export const LayoutHeader: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();

  return (
    <Layout.Header className={styles.header}>
      <div className={styles.logo}>
        <img src={logoIcon} className={'App-logo'} alt={'logo'} />
      </div>

      <div className={styles.menu} />

      {isAuthenticated && (
        <>
          <div className={styles.user}>
            <Typography.Text>Alexander Petrov</Typography.Text>

            <Avatar shape={'circle'} size={30} icon={<UserOutlined />} />
          </div>

          <Button className={styles.action} onClick={logout}>
            <img src={logoutIcon} className={'App-logo'} alt={'logo'} />
          </Button>
        </>
      )}
    </Layout.Header>
  );
};
